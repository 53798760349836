import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const containerStyles: BoxStyleProps = {
    display: "flex",
    justifyContent: "normal",
    alignItems: "center",
    backgroundColor: "colorBackgroundPrimaryWeakest",
    padding: "space20",
    paddingTop: "space40",
    paddingBottom: "space40",
    borderTopLeftRadius: "borderRadius20",
    borderTopRightRadius: "borderRadius20",
    backgroundImage: "url(/assets/imgs/twilio/Vector.png)",
    backgroundSize: "cover",
    height: "130px"
};

export const titleStyles: TextStyleProps = {
    color: "colorTextWeakest",
    paddingLeft: "space30"
};
