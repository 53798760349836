import { CSSProperties } from "react";
/* eslint-disable import/no-unused-modules */

export const logoStyles: CSSProperties = {
    marginLeft: "1rem",
    marginRight: "1rem",
    width: "80px"
};

export const rightAlignStyles: CSSProperties = {
    alignSelf: "flex-start",
    marginLeft: "auto",
    marginRight: ".75rem"
};

export const minimizeStyles: CSSProperties = {
  alignSelf: "flex-start",
  marginLeft: "auto",
  marginRight: ".75rem",
  marginTop: "-.85rem"
};

export const closeBtnStyles: CSSProperties = {
    fontSize: "2.15rem",
    backgroundColor: "transparent",
    border: "none",
    color: "#fff",
    padding: "0",
    height: "2.15rem",
    width: "2.15rem",
    cursor: "pointer"
};

export const buttonRightAlignStyles: CSSProperties = {
    display: "inline-block",
    width: "auto",
    marginLeft: "0",
    marginRight: "0.3rem"
}

export const buttonBaseRightAlignStyles: CSSProperties = {
    display: "inline-block",
    textAlign: "right",
    float: "right",
    width: "100%"
}

export const linkStyles: CSSProperties = {
    color: "#999999",
    textDecoration: "underline",
    cursor: "pointer",
}

export const overlayStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'rgba(10, 10, 10, 0.86)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
  };
  
  export const modalStyle: CSSProperties = {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingLeft: 10,
    width: '80%',
    height: '80%',
    maxWidth: 800,
    maxHeight: '80%',
    overflow: 'hidden',
    position: 'relative',
  };

  export const closeButtonStyle: CSSProperties = {
    color: '#4e4c4c',
    position: 'absolute',
    top: 10,
    right: 10,
    fontSize: 32,
    fontWeight: 'normal',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }

  export const modalIframeStyle: CSSProperties = {
    width: '100%',
    height: '90%',
    border: 'none',
  }

/*
 *export const statusStyles = (conversationState: string): CSSProperties => ({
 * // export const statusStyles: CSSProperties = {
 *    width: "12px",
 *    height: "12px",
 *    display: "block",
 *    border: "2px solid #fff",
 *    backgroundColor: conversationState === 'active' ? "#3dc58b" : "#6f6f6f",
 *    borderRadius: "50%",
 *    position: "relative",
 *    top: "16px",
 *    left: "23px",
 *});
 */
