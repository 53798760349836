// Number of messages fetched at a time from Conversations SDK
export const MESSAGES_LOAD_COUNT = 50;

/*
 * The maximum number of characters allowed in message input
 *export const CHAR_LIMIT = 32 * 1024 / 100; // (originally 32kb)
 */

 export const CHAR_LIMIT = 640; // (640 lengths)


// The height of the loading spinner box shown in the message list when fetching new messages
export const MESSAGES_SPINNER_BOX_HEIGHT = 50;
