import React, { useState, useEffect } from 'react';
import { overlayStyle, modalStyle, closeButtonStyle, modalIframeStyle } from "./styles/Customize.styles";

type ModalProps = {
  onClose: () => void;
  termsHTML: string;
};

const Modal: React.FC<ModalProps> = ({ onClose, termsHTML }) => {
  const [iframeContent, setIframeContent] = useState<string>('');

  const termsOfUseStyle = `
    h1 {
      color: #4e4c4c;
      display: block;
      font-weight: bold;
      font-size: 28px !important;
      font-family: 'myriad-pro', sans-serif !important;
    }
    h6 {
      color: #707070;
      font-size: 14px;
      font-weight: normal;
      font-family: 'myriad-pro', sans-serif !important;
    }
    .pl-12 {
      padding-inline-start: 12px;
      padding-left: 12px;
      font-family: 'myriad-pro', sans-serif !important;
    }

    ::-webkit-scrollbar {
      width: 8px; /* Width of the scrollbar */
    }
    
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Background color of the track */
      border-radius: 10px; /* Rounded edges of the track */
    }
    
    ::-webkit-scrollbar-thumb {
      background-color: #888; /* Color of the thumb */
      border-radius: 10px; /* Rounded edges of the thumb */
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Color of the thumb on hover */
    }
`;

  useEffect(() => {
    const fetchData = async () => {
      setIframeContent(termsHTML);
    };
    fetchData();
  }, []);

  return (
    <div
      style={overlayStyle}
      onClick={onClose}
    >
      <div
        style={modalStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          style={closeButtonStyle}
          onClick={onClose}
        >
          &times;
        </button>
        <br></br>
        <br></br>
        <iframe
          title="terms_of_use"
          srcDoc={`<!DOCTYPE html><html><head><style>${termsOfUseStyle}</style></head><body>${iframeContent}</body></html>`}
          style={modalIframeStyle}
        />
      </div>
    </div>
  );
};

export default Modal;