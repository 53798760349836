import { BoxStyleProps } from "@twilio-paste/core/box";

export const outerContainerStyles = (expanded: boolean): BoxStyleProps => ({
    position: "fixed",
    // bottom: "space50",
    bottom: "0",
    // right: "space40",
    right: "0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    zIndex: "zIndex90",
    height: expanded ? "100%" : "auto"
});

export const innerContainerStyles: BoxStyleProps = {
    boxShadow: "shadow",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "420px",
    height: "100%",
    maxHeight: "920px",
    marginBottom: "space30",
    marginLeft: "space20",
    borderRadius: "borderRadius30",
    backgroundColor: "colorBackgroundBody",
    marginTop: "auto"
};
