import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { containerStyles, titleStyles } from "./styles/Header.styles";
import { logoStyles, closeBtnStyles, minimizeStyles, linkStyles } from "./styles/Customize.styles";
import { AppState } from "../store/definitions";
import { useDispatch, useSelector } from "react-redux";
import { changeExpandedStatus } from "../store/actions/genericActions";
import Modal from './Modal';
import { useState, useEffect } from "react";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    const dispatch = useDispatch();
    const expanded = useSelector((state: AppState) => state.session.expanded);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [termsHTML, setTermsHTML] = useState('');

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
      //console.log("Temrs init");
      const fetchData = async () => {
      const response = await fetch('https://www.wiseprepay.co.nz/terms/chatbot');
      const html = await response.text();
      const startString = '<div id="content-main-wrap" class="is-clearfix">';
      const endString = '</div>';
      const startIndex = html.indexOf(startString);
      const endIndex = html.indexOf(endString, startIndex + startString.length);
      const filteredHtml = html.slice(startIndex, endIndex + endString.length);
      setTermsHTML(filteredHtml);
      };
      fetchData();
    }, []);

    return (
        <Box as="header" {...containerStyles}>
        <div>
          <img src={customTitle === 'Alice' ? '/assets/imgs/twilio/Willa.png' : `/assets/imgs/twilio/${customTitle}.png`} alt='' style={logoStyles} />
        </div>
        <div>
          <Text as="p" fontSize="fontSize30" {...titleStyles}>Chat with</Text>
          <Text as="h2" fontSize="fontSize60" fontWeight="fontWeightBold" {...titleStyles}>{customTitle  === 'Alice' ? "Willa" : customTitle}</Text>
          <Text as="p" fontSize="fontSize20" onClick={handleModalToggle} {...titleStyles} style={linkStyles}>
          Terms of Use
          </Text>
        </div>
        <div style={minimizeStyles}>
          <button style={closeBtnStyles} onClick={() => dispatch(changeExpandedStatus({ expanded: !expanded }))}>
            <img src='/assets/imgs/twilio/transporter-empty-duotone.svg' />
          </button>
        </div>
        {isModalOpen && (
          <Modal onClose={handleModalToggle} termsHTML={termsHTML}>
          </Modal>
        )}
      </Box>
    );
};
