import log from "loglevel";
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import throttle from "lodash.throttle";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@twilio-paste/core/box";
import { InputBox } from "@twilio-paste/core/input-box";
import { TextArea } from "@twilio-paste/core/textarea";
import { Button } from "@twilio-paste/core/button";
import { ProductMarketingCampaignsIcon } from "@twilio-paste/icons/esm/ProductMarketingCampaignsIcon";

import { AppState, EngagementPhase } from "../store/definitions";
import { AttachFileButton } from "./AttachFileButton";
import { FilePreview } from "./FilePreview";
import { detachFiles, changeEngagementPhase, addNotification } from "../store/actions/genericActions";
import { CHAR_LIMIT } from "../constants";
import {
    formStyles,
    innerInputStyles,
    messageOptionContainerStyles,
    filePreviewContainerStyles,
    textAreaContainerStyles
} from "./styles/MessageInput.styles";

import { sessionDataHandler } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";
import { notifications } from "../notifications";

export const MessageInput = ({
    isGptChatActiveBase,
    twilioChatLaunchBase,
    isMessageInputDisableBase,
    updateSendingGptMessage
}: {
    isGptChatActiveBase: boolean;
    twilioChatLaunchBase: string;
    isMessageInputDisableBase: boolean;
    updateSendingGptMessage: (message: string) => void;
}) => {
    const dispatch = useDispatch();
    const [text, setText] = useState("");
    const [isSending, setIsSending] = useState(false);
    const { messages, conversation, attachedFiles, fileAttachmentConfig } = useSelector((state: AppState) => ({
        messages: state.chat.messages,
        conversation: state.chat.conversation,
        attachedFiles: state.chat.attachedFiles || [],
        fileAttachmentConfig: state.config.fileAttachment
    }));
    const [isOnline, setIsOnline] = useState(true);

    const { customerInfo, predefinedMessage } = useSelector((state: AppState) => ({
        customerInfo: state.config.customerInfo,
        predefinedMessage: state.config.predefinedMessage,
        messages: state.chat.messages
    }));

    // if latest message is interactive
    const isInteractive = (messages !== undefined) ? ((messages[messages?.length-1]?.attributes !== undefined &&'interactiveWebchatOptions' in  messages[messages?.length-1]?.attributes) ? true : false) : false;

    const oldAttachmentsLength = useRef((attachedFiles || []).length);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const attachmentsBoxRef = useRef<HTMLDivElement>(null);

    // this MessageInput.tsx resetChat async has to be the same in MessagingCanvasPhase.tsx initWebchat async.
    const resetChat = async () => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession(
                {
                    formData: {
                        // should pass customer name and email addr from megatel homepage session
                        friendlyName: customerInfo?.name_en,
                        email: customerInfo?.email,
                        siteType: customerInfo?.siteType
                    }
                },
                { ...predefinedMessage }
            );
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }        
    };

    function isFail(error: string) {
        if(error.toString().indexOf("Error: Unable to connect: Access Token expired or expiration date invalid") > -1
          ) {
            resetChat();
        }
    }

    const throttleChange = useMemo(
        () =>
            throttle(() => {
                conversation?.typing();

                // in case the input was already focused, let's make sure to send the `read` status if the customer is typing
                if (conversation?.lastReadMessageIndex !== conversation?.lastMessage?.index) {
                    conversation?.setAllMessagesRead().then().catch(isFail);
                }
            }, 500),
        [conversation]
    );

    const isSubmitDisabled = (!text.trim() && !attachedFiles?.length) || isSending;

    const send = async () => {
        /*
         * [DEBUG CONSOLE]
         * console.log("You are sending");
         */
        if(isGptChatActiveBase) {
            /*
             * [DEBUG CONSOLE]
             * console.log("GPT chat active");
             * console.log(text);
             */
            updateSendingGptMessage(text);
            setText("");
        }
        else {
            /*
             * [DEBUG CONSOLE]
             * console.log("Not GPT chat");
             */
            if (isSubmitDisabled) {
                /*
                 * [DEBUG CONSOLE]
                 * console.log("Not Sent 1");
                 */
                return;
            }
            if (!conversation) {
                /*
                 * [DEBUG CONSOLE]
                 * console.log("Not Sent 2");
                 */
                log.error("Failed sending message: no conversation found");
                return;
            }
            setIsSending(true);
    
            /*
             * [DEBUG CONSOLE]
             * console.log("Sending...");
             */

            let preparedMessage = conversation.prepareMessage();
            preparedMessage = preparedMessage.setBody(text);
            attachedFiles.forEach((file: File) => {
                const formData = new FormData();
                formData.append(file.name, file);
                preparedMessage.addMedia(formData);
            });
            await preparedMessage.build().send();
            setText("");
            dispatch(detachFiles(attachedFiles));
            setIsSending(false);
            textAreaRef.current?.focus();
        }
    };

    const forceSend = async (forceText: string) => {
        /*
         * [DEBUG CONSOLE]
         * console.log("You are sending (forceSend)");
         */
        if(isGptChatActiveBase) {
            console.log("GPT chat active (forceSend)");
        }
        else {
            /*
             * [DEBUG CONSOLE]
             * console.log("Not GPT chat (forceSend)");
             */

            if (!conversation) {
                /*
                 * [DEBUG CONSOLE]
                 * console.log("Not Sent 2 (forceSend)");
                 */
                log.error("Failed sending message: no conversation found");
                return;
            }
            setIsSending(true);
            /*
             * [DEBUG CONSOLE]
             * console.log("Sending... (forceSend)");
             * console.log(forceText);
             * console.log("Sending done... (forceSend)");
             */

            let preparedMessage = conversation.prepareMessage();
            preparedMessage = preparedMessage.setBody(forceText);

            await preparedMessage.build().send();
            setText("");
            dispatch(detachFiles(attachedFiles));
            setIsSending(false);
            textAreaRef.current?.focus();
        }
    };

    useEffect(() => {
        setIsOnline(navigator.onLine);
    }, [navigator.onLine]);
    
    useEffect(() => {
        if(twilioChatLaunchBase.includes("BTTCODE")) {
            forceSend(twilioChatLaunchBase);
            /*
             * [DEBUG CONSOLE]
             * console.log("force send is with it:");
             * console.log(twilioChatLaunchBase);
             * console.log("force send is with it end:");
             * twilioChatLaunchBase = "";
             */
        }

    }, [twilioChatLaunchBase]);

    const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            (e.target as HTMLInputElement).form?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }
    };

    const onChange = (val: ChangeEvent<HTMLTextAreaElement>) => {
        setText(val.target.value);

        throttleChange();
    };

    const onFocus = () => {
        conversation?.setAllMessagesRead().then().catch(isFail);
    };

    useEffect(() => {
        textAreaRef.current?.setAttribute("rows", "1");
        textAreaRef.current?.focus();
    }, [textAreaRef]);

    // Ensuring attached files are automatically scrolled to
    useEffect(() => {
        if (!attachmentsBoxRef.current) {
            return;
        }

        if (attachedFiles.length > oldAttachmentsLength.current) {
            (attachmentsBoxRef.current.lastChild as Element)?.scrollIntoView();
        }

        oldAttachmentsLength.current = attachedFiles.length;
    }, [attachedFiles]);

    return (
        <Box
            as="form"
            {...formStyles}
            onSubmit={(e) => {
                e.preventDefault();
                send();
            }}
        >
            <InputBox element="MESSAGE_INPUT_BOX" disabled={isSending || isInteractive || isMessageInputDisableBase}>
                <Box as="div" {...innerInputStyles}>
                    <Box {...textAreaContainerStyles}>
                        <TextArea
                            ref={textAreaRef}
                            data-test="message-input-textarea"
                            placeholder={isInteractive? "Please select an option" : isMessageInputDisableBase ? (isOnline ? "Waiting for Willa..." : "Your internet is disconnected.") : "Type your message"}
                            value={text}
                            element="MESSAGE_INPUT"
                            onChange={onChange}
                            onFocus={onFocus}
                            readOnly={isSending || isInteractive || isMessageInputDisableBase}
                            onKeyPress={onKeyPress}
                            maxLength={CHAR_LIMIT}
                        />
                    </Box>
                    <Box {...messageOptionContainerStyles}>
                        {fileAttachmentConfig?.enabled && !isInteractive && <AttachFileButton textAreaRef={textAreaRef} />}
                    </Box>
                    <Box {...messageOptionContainerStyles}>
                        <Button
                            data-test="message-send-button"
                            variant="primary_icon"
                            size="icon_small"
                            type="submit"
                            aria-disabled={isSubmitDisabled}
                        >
                            <ProductMarketingCampaignsIcon decorative={false} title="Send message" size="sizeIcon40" />
                        </Button>
                    </Box>
                </Box>
                {attachedFiles && (
                    <Box data-test="message-attachments" {...filePreviewContainerStyles} ref={attachmentsBoxRef}>
                        {attachedFiles.map((file, index) => (
                            <FilePreview
                                focusable={true}
                                key={index}
                                file={file}
                                isBubble={false}
                                disabled={isSending || isInteractive}
                            />
                        ))}
                    </Box>
                )}
            </InputBox>
        </Box>
    );
};
