import { Spinner } from "@twilio-paste/core/spinner";
import { NotificationBar } from "./NotificationBar";
import { Header } from "./Header";

export const LoadingWithMessagingCanvasPhase = () => {
    return (
      <>
        <Header customTitle={"Alice"} />
        <NotificationBar />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <Spinner title="Authorizing" decorative={false} size="sizeIcon100" color="colorTextLink" />
          <div style={{ marginLeft: "10px" }}>
            <p>Please wait...</p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: "31.625rem" }}>
        </div>
      </>
    );
};