import { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "./Header";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { AppState, EngagementPhase } from "../store/definitions";
import { ConversationEnded } from "./ConversationEnded";
import { NotificationBar } from "./NotificationBar";
import { removeNotification, updatePreEngagementData, changeEngagementPhase, addNotification } from "../store/actions/genericActions";
import { notifications } from "../notifications";
import { AttachFileDropArea } from "./AttachFileDropArea";

import { sessionDataHandler } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";

export const MessagingCanvasPhase = () => {
    const dispatch = useDispatch();
    const { conversationState, startEngagementOnInit, customerInfo, predefinedMessage, messages, users } = useSelector((state: AppState) => ({
        conversationState: state.chat.conversationState,
        startEngagementOnInit: state.config.startEngagementOnInit?.enabled,
        customerInfo: state.config.customerInfo,
        predefinedMessage: state.config.predefinedMessage,
        messages: state.chat.messages,
        users: state.chat.users
    }));
    const [isGptChatActiveBase, setIsGptChatActiveBase] = useState(false);
    const [twilioChatLaunchBase, setTwilioChatLaunchBase] = useState("");
    const [isMessageInputDisableBase, setIsMessageInputDisable] = useState(false);
    const [isForceInitWebChatBase, setIsForceInitWebChatBase] = useState(false);
    const [sendingGptMessage, setSendingGptMessage] = useState("");

    // last non customer message
    let lastNonCustAuthor = "Alice";
    if(messages) {
        // reverse forloop
        for(let i = messages.length-1; i >= 0; i--) {
            if(messages[i].author.length !== 34) {
                lastNonCustAuthor = messages[i].author
                break;
            }
        }
    }

    const updateSendingGptMessage = (text: string) => {
        /*
         * [DEBUG CONSOLE]
         * console.log("set sending gpt message.");
         * console.log(text);
         */

        setSendingGptMessage(text);

        // Clear the sendingGptMessage after 0.5 second
        setTimeout(() => {
            setSendingGptMessage("");
        }, 500);
    };

    const updateIsGptChatActiveBase = (updateIsGptChatActive: boolean) => {
        setIsGptChatActiveBase(updateIsGptChatActive);
    };

    const updateTwilioChatLaunchBase = (updateTwilioChatLaunch: string) => {
        setTwilioChatLaunchBase(updateTwilioChatLaunch);
    };

    const updateMessageInputDisableBase = (updateMessageInputDisable: boolean) => {
        setIsMessageInputDisable(updateMessageInputDisable);
        
        /*
         * [DEBUG CONSOLE]
         * console.log("changed to");
         * console.log(updateMessageInputDisable);
         */
    };

    const updateIsForceInitWebChatBase = (isForceInitWebChat: boolean) => {
        setIsForceInitWebChatBase(isForceInitWebChat);
    };

    // last messager name for Header.
    const customTitle = messages? 
        // if there is no user identity for a message author, it must be Alice as it is not a user.
        users?.find((u) => u.identity === lastNonCustAuthor)?.friendlyName || "Alice": 
        // if there are no messages, default to Alice.
        "Alice";

    const initWebchat = async () => {
        dispatch(changeEngagementPhase({ phase: EngagementPhase.Loading }));
        try {
            const data = await sessionDataHandler.fetchAndStoreNewSession(
                {
                    formData: {
                        // should pass customer name and email addr from megatel homepage session
                        friendlyName: customerInfo?.name_en,
                        email: customerInfo?.email,
                        siteType: customerInfo?.siteType
                    }
                },
                { ...predefinedMessage }
            );
            dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
        } catch (err) {
            dispatch(addNotification(notifications.failedToInitSessionNotification((err as Error).message)));
            dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
        }        
    };

    useEffect(() => {
        if(isForceInitWebChatBase) {
            initWebchat();
        }
    }, [isForceInitWebChatBase]);


    useEffect(() => {
        if(startEngagementOnInit && conversationState !== "active") {
            initWebchat();
        }
        else {
            dispatch(updatePreEngagementData({ email: "", name: "", query: "" }));
            dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
        }
    }, [dispatch]);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;
    return (
        <>
            {(startEngagementOnInit && conversationState === undefined) ? (
                // prevents <ConversationEnded /> being shown for split second, on first initialisation
                <></>
            ) : (
                <Wrapper>
                    <Header customTitle={customTitle} />
                    <NotificationBar />
                    <MessageList 
                        updateIsGptChatActive={updateIsGptChatActiveBase}
                        updateTwilioChatLaunch={updateTwilioChatLaunchBase}
                        updateMessageInputDisable={updateMessageInputDisableBase}
                        updateIsForceInitWebChat={updateIsForceInitWebChatBase}
                        sendingGptMessage={sendingGptMessage}
                        conversationStateToCheckInMessageList={conversationState}
                    />
                    {conversationState === "active" ?
                        <MessageInput 
                            isGptChatActiveBase={isGptChatActiveBase}
                            twilioChatLaunchBase={twilioChatLaunchBase}
                            isMessageInputDisableBase={isMessageInputDisableBase}
                            updateSendingGptMessage={updateSendingGptMessage}
                        /> : <ConversationEnded initWebchat={initWebchat} />}
                </Wrapper>
            )}
        </>
    );
};
