/**
 * Based on blogpost: https://www.twilio.com/blog/twilio-flex-webchat-interactive
 * edited to work with conversations api webchat
 */

// Package Imports
import React, { useState, useEffect, useRef } from 'react';

// Component Imports
import Buttons from './Buttons';

// Consts
const defaultCurInteractives = { type: '' };

// Component
const Interactives = ({ message, conversation }) => {
	// Refs
	const interactivesContainer = useRef(null);

	const [curInteractives, setCurInteractives] = useState(
		defaultCurInteractives
	);

	// Effects
	useEffect(() => {
		setCurInteractives(message.attributes.interactiveWebchatOptions);
	}, [message]);

	useEffect(() => {
		if (curInteractives !== defaultCurInteractives) {
			interactivesContainer.current.scrollIntoView();
		}
	}, [curInteractives]);

	// Functions
	const sendMessage = async (msg) => {
		await conversation.sendMessage(msg);
	};

	// Render
	return (
		<>
			{curInteractives !== defaultCurInteractives ? (
				<div ref={interactivesContainer}>
					{curInteractives.type === 'buttons' ? (
						<Buttons
							curInteractives={curInteractives}
							sendMessage={sendMessage}
						/>
					) : (
						<></>
					)}
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default Interactives;
