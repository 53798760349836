import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { Button } from "@twilio-paste/core/button";
import { useDispatch, useSelector } from "react-redux";

import { sessionDataHandler } from "../sessionDataHandler";
import { changeEngagementPhase } from "../store/actions/genericActions";
import { AppState, EngagementPhase } from "../store/definitions";
import { containerStyles, textStyles, titleStyles } from "./styles/ConversationEnded.styles";

export const ConversationEnded = ({initWebchat}: {initWebchat: () => void}) => {
    const dispatch = useDispatch();
    const startEngagementOnInit = useSelector((state: AppState) => state.config.startEngagementOnInit?.enabled);

    const handleClick = () => {
        sessionDataHandler.clear();
        dispatch(changeEngagementPhase({ phase: startEngagementOnInit? EngagementPhase.MessagingCanvas : EngagementPhase.PreEngagementForm }));
        if(startEngagementOnInit) initWebchat();
    };

    return (
        <Box {...containerStyles} style={{boxShadow: "4px 0 30px 0 rgba(0, 0, 0, 0.16)"}}>
            <Text as="h3" {...titleStyles}>
                Thanks for chatting with us!
            </Text>
            <Text as="p" {...textStyles}>
                If you have any more questions, feel free to reach out again.
            </Text>
            <Button variant="primary" data-test="start-new-chat-button" fullWidth={true} onClick={handleClick}>
                Start new chat
            </Button>
        </Box>
    );
};
