import { UIEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";
import { Spinner } from "@twilio-paste/core/spinner";
import { Message } from "@twilio/conversations";
import throttle from "lodash.throttle";

import { MessageBubble } from "./MessageBubble";
import { AppState } from "../store/definitions";
import { getMoreMessages } from "../store/actions/genericActions";
import { MessageListSeparator } from "./MessageListSeparator";
import { MESSAGES_SPINNER_BOX_HEIGHT } from "../constants";
import {
    conversationEventContainerStyles,
    conversationEventTitleStyles,
    conversationEventDateStyles,
    spinnerContainerStyles,
    messageListStyles,
    innerContainerStyles,
    outerContainerStyles,
    participantTypingStyles
} from "./styles/MessageList.styles";

// Component Imports
import Interactives from './Interactives';

/*
 * const isLastOfUserGroup = (message: Message, i: number, messages: Message[]) => {
 *     const nextMessage = messages[i + 1];
 * 
 *     // if there's no message afterwards, it's definitely the last of a group
 *     if (!nextMessage) {
 *         return true;
 *     }
 * 
 *     // if the author of the next message is different from current one's, then yes, this message is last of its group
 *     return nextMessage.author !== message.author;
 * };
 */

interface ChatMessage {
    user?: string;
    alice?: string;
    date: Date;
}

export const MessageList = ({
    updateIsGptChatActive,
    updateTwilioChatLaunch,
    updateMessageInputDisable,
    updateIsForceInitWebChat,
    sendingGptMessage,
    conversationStateToCheckInMessageList
} : {
    updateIsGptChatActive: (updateIsGptChatActive: boolean) => void;
    updateTwilioChatLaunch: (updateTwilioChatLaunch: string) => void;
    updateMessageInputDisable: (updateMessageInputDisable: boolean) => void;
    updateIsForceInitWebChat: (updateIsForceInitWebChat: boolean) => void;
    sendingGptMessage: string;
    conversationStateToCheckInMessageList: string | undefined;
}) => {
    const { messages, participants, users, conversation, conversationsClient } = useSelector((state: AppState) => ({
        messages: state.chat.messages,
        participants: state.chat.participants,
        users: state.chat.users,
        conversation: state.chat.conversation,
        conversationsClient: state.chat.conversationsClient
    }));
    const dispatch = useDispatch();
    const messageListRef = useRef<HTMLDivElement>(null);
    const isLoadingMessages = useRef(false);
    const oldMessagesLength = useRef((messages || []).length);
    const realTimeMaxIndex = useRef(messages ? messages[messages.length - 1].index : 0);
    const [hasLoadedAllMessages, setHasLoadedAllMessages] = useState(true);
    const [focusIndex, setFocusIndex] = useState(
        messages && messages.length ? messages[messages?.length - 1].index : -1
    );
    const [shouldFocusLatest, setShouldFocusLatest] = useState(false);
    const [isAlicePreparing, setIsAlicePreparing] = useState("");
    const [userId, setUserId] = useState(""); //34 length id

    const [historicalGptChatMessages, setHistoricalGptChatMessages] = useState<Message[]>([]);
    const [gptChatMessages, setGptChatMessages] = useState<Message[]>();
    const [gptChatOneQueueCompleted, setGptChatOneQueueCompleted] = useState(false);
    const [gptChatLogLoaded, setGptChatLogLoaded] = useState(false);

    const [selfCheckGptErrorCount, setSelfCheckGptErrorCount] = useState(0);

    const [isOnline, setIsOnline] = useState(true);

    const updateFocus = (newFocus: number) => {
        if (newFocus < 0 || !messages || !messages.length || newFocus > messages[messages.length - 1].index) {
            return;
        }

        if (shouldFocusLatest) {
            setFocusIndex(messages[messages.length - 1].index);
            setShouldFocusLatest(false);
        } else {
            setFocusIndex(newFocus);
        }
    };

    const scrollToBottom = () => {
        if (!messageListRef.current) {
            return;
        }
        const isIOS = /ipad|iPhone|iPod/.test(navigator.userAgent);

        if(isIOS) messageListRef.current.scrollTop = -1;
        else messageListRef.current.scrollTop = 0;
    };

    useEffect(() => {
        setIsOnline(navigator.onLine);
    }, [navigator.onLine]);

    useEffect(() => {
        /*
         * [DEBUG CONSOLE]
         * console.log(isAlicePreparing);
         * console.log("is running...");
         */
        
        if(isAlicePreparing !== "") {
            updateMessageInputDisable(true);
        }
        else {
            updateMessageInputDisable(false);
        }

    }, [isAlicePreparing]);


    const getGptChatByChid = (userIdPassed: string) => {
        /*
         * [DEBUG CONSOLE 7]
         * console.log("RUN");
         */
        
        const getGptChatByChidRequest = async (userIdToFill: string) => {

            try {
            let conversationSid = null;
            let token = null;
            let gpt = null;
            let accountNumber = null;
    
            const twilioWebchatWidget = localStorage.getItem('TWILIO_WEBCHAT_WIDGET');
    
            if (twilioWebchatWidget) {
                const { conversationSid: extractedConversationSid, token: extractedToken, gpt: extractedGpt, accountNumber: extractedAccountNumber } = JSON.parse(twilioWebchatWidget);
                conversationSid = extractedConversationSid;
                token = extractedToken;
                gpt = extractedGpt;
                accountNumber = extractedAccountNumber;
            }
    
            const url = 'https://api.wiseprepay.co.nz/api/v1/twilio/get_twilio_gpt_temp_chatlog';
    
                /* eslint-disable */
                const data = {
                    "body": {
                      "data": {
                        "body": [
                          {
                            "user_current_input": "",
                            "aid": accountNumber,
                            "is_debug": "N",
                            "user_id": "brian"
                          },
                          {
                            "encodedPrevChat": ""
                          },
                          {
                            "conversationSid": conversationSid,
                            "token": token,
                            "gpt": gpt
                          },
                        ]
                      }
                    }
                  };
                /* eslint-enable */
    
                        const response = await fetch(url, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'token-head': 'twilioappaccess'
                          },
                          body: JSON.stringify(data)
                        });
                      
                        const responseData = await response.json();
                        const messagesXXX: Message[] = [];

                        /*
                         * [DEBUG CONSOLE 5]
                         * console.log("WHAT IS USER ID?");
                         * console.log(userId);
                         */

                          for (let index = 0; index < responseData.response.length; index++) {
                            const dataResponded = responseData.response[index];
                            messagesXXX.push({
                              body: dataResponded.message,
                              author: dataResponded.from === 'user' ? userIdToFill : dataResponded.from,
                              dateUpdated: new Date(dataResponded.created),
                              dateCreated: new Date(dataResponded.created),
                              attributes: {},
                              index: (index + 2) * -1
                            } as Message);
                          }

                        setHistoricalGptChatMessages(messagesXXX);

                      } catch (error) {
                        // Handle any errors here
                        console.error('Error:', error);
                      }
        }

        getGptChatByChidRequest(userIdPassed);

    };

    function dateFormatChanger(messageText: string) {
        const pattern = /\b(0{4}|[1-9]\d{3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])\b/g; // pattern for date format YYYY-MM-DD
        const newDateFormat = "$3/$2/$1"; // format to convert YYYY-MM-DD to DD/MM/YYYY
    
        // Replace date patterns with the new format (YYYY-MM-DD to DD-MM-YYYY)
        messageText = messageText.replace(pattern, newDateFormat);
        messageText = messageText.replace("9999-99-99","99/99/9999");
        messageText = messageText.replace("YYYY-MM-DD","DD/MM/YYYY");
    
        return messageText;
    }

    function replaceHtmlTagsInEarlyStage(inputString: string) {
        const pattern = /\[([^[\]]+)\]\(?(https?:\/\/[^\s)]+)\)?/gi;
        return (inputString.replace(pattern, '<a href="$2" target="_blank">$1</a>'));
    }

    function replaceHtmlTagsInEarlyStageForMailTo(inputString: string) {
        const pattern = /\[([^[\]]+)\](?:\(?(mailto:)?([^@\s)]+@[^)\s]+)\))?/gi;
        return (inputString.replace(pattern, '<a href="$2$1" target="_blank">$1</a>'));
    }

    function replaceHtmlTags(inputString: string, inputAuthor: string) {
        inputString = replaceHtmlTagsInEarlyStage(inputString);
        inputString = replaceHtmlTagsInEarlyStageForMailTo(inputString);
        inputString = inputString.replace(/\u201C/g, '"')
        inputString = inputString.replace(/\n/g, '<br>');
        inputString = dateFormatChanger(inputString);

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(inputString, 'text/html');

        inputString = htmlDoc.documentElement.outerHTML.replace(/(?<!<a[^>]*>)\b(https?:\/\/(?!<br>)[^\s<]+)(?![^<]*<\/a>)/g, '<a target="_blank" href="$1">$1</a>').replace(/^(<br>)+/, "").replace(/(<br>){3,50}/g, "<br><br>").replace(/(<p[^>]*>)(\s*<br\s*\/?>)+/g, '$1').replace(/(<body[^>]*>)(\s*<br\s*\/?>)+/g, '$1')

        // Define the patterns to search for
        const patterns: [RegExp, string][] = (inputAuthor === "Alice") ? [
          [/<html><head><\/head><body>/g, ''],
          [/<\/body><\/html>/g, ''],
          [/<br><br><table/g, '<br><table'],
          [/<\/table><br><br>/g, '</table><br>'],
          [/<td>/g,'<td style="min-width: 6.25rem;">'],
          [/<th>/g,'<th style="min-width: 6.25rem;">'],
          [/as an AI language model/g, 'due to our chatbot policy'],
          [/as an AI assistant/g, 'due to our chatbot policy'],
          [/as an AI,/g, 'due to our chatbot policy,'],
          [/As an AI language model/g, 'Due to our chatbot policy'],
          [/As an AI assistant/g, 'Due to our chatbot policy'],
          [/As an AI,/g, 'Due to our chatbot policy,'],
          [/their customer service/g, 'our customer service'],
          [/html</g, '<'],
          [/```/g, ''],
        ] : 
          [
            [/<html><head><\/head><body>/g, ''],
            [/<\/body><\/html>/g, ''],
            [/<br><br><table/g, '<br><table'],
            [/<\/table><br><br>/g, '</table><br>'],
            [/<td>/g,'<td style="min-width: 6.25rem;">'],
            [/<th>/g,'<th style="min-width: 6.25rem;">'],
          ];
        // [/("?)\[here\]("?)\(https:\/\/\S+\)/g,'<a href="$1">$1</a>'],
      
        // Replace the patterns using regular expressions
        patterns.forEach(([pattern, replacement]) => {
          inputString = inputString.replace(pattern, replacement);
        });

        //replace (.) dot at the end, to not having (.) dot for a href tag.
        inputString = inputString.replace(/<a.*?href="(.*?)".*?>(.*?)<\/a>/, function (match, group1, group2) {
            return `<a href="${group1.replace(/\.$/, '')}" target="_blank">${group2}</a>`;
        });

        return inputString;
    }

    const handleGptCall = (userRequest: string) => {
        if(userRequest !== "") {
            setIsAlicePreparing("Preparing");

            let maxIndex3 = 0;

            if (messages) {
                messages.forEach((message) => {
                    if (message.index > maxIndex3) {
                        maxIndex3 = message.index;
                    }
                });
                /*
                 * [DEBUG CONSOLE]
                 * console.log("Max index number:", maxIndex3);
                 */
            }

            const urlEndpointAiDirectCallUrl = "https://api.wiseprepay.co.nz/api/v1/twilio/get_open_ai_direct_call";

           
            const makeChatCompletionRequest = async (maxIndex333: number, userRequestChat: string, urlEndpoint: string) => {
                    const messagesYYY: Message[] = [];
                    messagesYYY.push({
                        body: userRequestChat,
                        author: userId,
                        dateUpdated: new Date(),
                        dateCreated: new Date(),
                        attributes: {},
                        index: maxIndex333 + 1
                    } as Message);
                    setGptChatMessages(messagesYYY);
                    
                    const headers = {
                      'Content-Type': 'application/json',
                      'token-head': 'twilioappaccess' // Replace 'YOUR-HEADER' with your actual authorization token
                    };
        
                    const userInput = userRequestChat;
    
                    const allChat: ChatMessage[] = [];
    
                    //if historicalGptChatMessages is empty, pass CH id to search it from OUR DB.
    
                    historicalGptChatMessages.forEach((message) => {
                        if(message.author === "Alice") {
                            allChat.push({ alice: replaceHtmlTags(message.body, "Alice").replace(/"/g, "“"), date: new Date() });
                        }
                        else {
                            allChat.push({ user: replaceHtmlTags(message.body, "User").replace(/"/g, "“"), date: new Date() });
                        }
                    });
        
                    /*
                     * [DEBUG CONSOLE ALL MESSAGE]
                     * console.log("ALL MESSAGES:");
                     * console.log(allChat);
                     */
    
                    const twilioWebchatWidget = localStorage.getItem('TWILIO_WEBCHAT_WIDGET');
    
                    let conversationSid = null;
                    let token = null;
                    let gpt = null;
                    let accountNumber = null;
    
                    if (twilioWebchatWidget) {
                        const { conversationSid: extractedConversationSid, token: extractedToken, gpt: extractedGpt, accountNumber: extractedAccountNumber } = JSON.parse(twilioWebchatWidget);
                        conversationSid = extractedConversationSid;
                        token = extractedToken;
                        gpt = extractedGpt;
                        accountNumber = extractedAccountNumber;
                    }
                    
                    /* eslint-disable */
                    const data = {
                        "body": {
                          "data": {
                            "body": [
                              {
                                "user_current_input": userInput,
                                "aid": accountNumber,
                                "is_debug": "N",
                                "user_id": "brian"
                              },
                              {
                                "encodedPrevChat": allChat
                              },
                              {
                                "conversationSid": conversationSid,
                                "token": token,
                                "gpt": gpt
                              },
                            ]
                          }
                        }
                      };
                    /* eslint-enable */
                    
                    try {
                       
                      const response = await fetch(urlEndpoint, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(data)
                      });
    
                      if (!response.ok) {
                        throw new Error("Not ok exception.");
                      }
    
                      const reader = response?.body?.getReader();
              
                      // Function declared in a loop contains unsafe references to variable(s) 'fullText', 'fullText', 'fullText'
                    // eslint-disable-next-line no-loop-func
                      let fullText = "";
        
                      //CODE 0. define original time
                      let originalTime = null;
    
                      while (true) {
                        //CODE 1. set original time
                        if(originalTime === null) {
                          originalTime = Date.now();
                        }
    
                        const result = await reader?.read();
        
                        if(result) {
                            const { done, value } = result;
        
                            if (done) {
                                setIsAlicePreparing("");
                                setGptChatOneQueueCompleted(true);
                                break;
                            }
                                                
                            //CODE 2. if original time - current time over 60 seconds make timeout (error)
                            if (Date.now() - originalTime > 60000) {
                                throw new Error("Connection timeout");
                            }
    
                            // Handle the received chunk of data
                            const chunk = new TextDecoder().decode(value);
                            
                            const lines = chunk.split("\n");
        
        
                      // Function declared in a loop contains unsafe references to variable(s) 'fullText', 'fullText', 'fullText'
                    // eslint-disable-next-line no-loop-func
                            lines.forEach(function(line) {
                                const indexOfData = line.indexOf("data: ");
        
                                if (indexOfData !== -1) {
                                    const dataString = line.split("data: ")[1];
        
                                    //console.log(dataString);
        
                                    try {
                                        const jsonData = JSON.parse(dataString);
                                        
                                        const messagesXXX: Message[] = [];
                                        fullText = [fullText, jsonData.choices[0].delta.content].join('');
    
                                        const replacedHTML = replaceHtmlTags(fullText, "Alice");
    
                                        //console.log(replacedHTML);
    
                                        //CODE 3. set original time to current time
                                        originalTime = Date.now();
    
                                        messagesXXX.push({
                                            body: userRequestChat,
                                            author: userId,
                                            dateUpdated: new Date(),
                                            dateCreated: new Date(),
                                            attributes: {},
                                            index: maxIndex333 + 1
                                        } as Message);
    
                                        messagesXXX.push({
                                            body: replacedHTML,
                                            author: "Alice",
                                            dateUpdated: new Date(),
                                            dateCreated: new Date(),
                                            attributes: {},
                                            index: maxIndex333 + 2
                                        } as Message);
        
                                        setIsAlicePreparing("Typing");
                                        setGptChatMessages(messagesXXX);
                                    } catch (error) {
                                        console.error('Invalid JSON string:', error);
                                    }
                                }
                            });
        
                            // Process the chunk as needed
                        }
                      }
                      
                      //If there were no error for normal gpt call.
                      if(urlEndpoint === urlEndpointAiDirectCallUrl) {
                        setSelfCheckGptErrorCount(0);
                      }
                    } catch (error) {
                        let maxIndex444 = 0;
    
                        if (messages) {
                            messages.forEach((message) => {
                                if (message.index > maxIndex444) {
                                    maxIndex444 = message.index;
                                }
                            });
                        }
    
                      if(urlEndpoint === urlEndpointAiDirectCallUrl) {
                        if(selfCheckGptErrorCount === 0) {
                            setSelfCheckGptErrorCount(1);

                            if (navigator.onLine) {
                                makeChatCompletionRequest(maxIndex444, userRequestChat, 'https://api.wiseprepay.co.nz/api/v1/twilio/get_open_ai_sorry_message');
                            }
                            else {
                                const interval = setInterval(function() {
                                    if (navigator.onLine) {
                                        clearInterval(interval);
                                        makeChatCompletionRequest(maxIndex444, userRequestChat, 'https://api.wiseprepay.co.nz/api/v1/twilio/get_open_ai_sorry_message');
                                    }
                                }, 3000);
                            }
                        }
                        else {
                            setSelfCheckGptErrorCount(0);

                            if (navigator.onLine) {
                                makeChatCompletionRequest(maxIndex444, userRequestChat, 'https://api.wiseprepay.co.nz/api/v1/twilio/get_open_ai_sorry_message_bttcode500500');
                            }
                            else {
                                const interval = setInterval(function() {
                                    if (navigator.onLine) {
                                        clearInterval(interval);
                                        makeChatCompletionRequest(maxIndex444, userRequestChat, 'https://api.wiseprepay.co.nz/api/v1/twilio/get_open_ai_sorry_message_bttcode500500');
                                    }
                                }, 3000);
                            }
                        }
                    }
                    else {
                        updateIsForceInitWebChat(true);
                    }
                      
                      /*
                       * WHY NOT
                       * updateTwilioChatLaunch(`Request failed.|Code: BTTCODE500500`);
                       * updateIsGptChatActive(false);
                       * setIsAlicePreparing("");
                       * setGptChatOneQueueCompleted(true);
                       */
                }
            };
          
            if (navigator.onLine) {
                makeChatCompletionRequest(maxIndex3, userRequest, urlEndpointAiDirectCallUrl);
            }
            else {
                const interval = setInterval(function() {
                    if (navigator.onLine) {
                        clearInterval(interval);
                        makeChatCompletionRequest(maxIndex3, userRequest, urlEndpointAiDirectCallUrl);
                    }
                }, 3000);
            }
        }
    };

    useEffect(() => {

        if(gptChatOneQueueCompleted === true && gptChatMessages) {
            /*
             * [DEBUG CONSOLE]
             * console.log("GPT COMPLETED:");
             * console.log(gptChatMessages);
             */

            const historicalGptChatMessagesData = historicalGptChatMessages;
            gptChatMessages.forEach((message: Message) => {
                historicalGptChatMessagesData.push({
                    body: replaceHtmlTags(message.body, message.author),
                    author: message.author,
                    dateUpdated: message.dateUpdated,
                    dateCreated: message.dateCreated,
                    attributes: message.attributes,
                    index: message.index,
                } as Message);
            });
            setHistoricalGptChatMessages(historicalGptChatMessagesData);

            const twilioWebchatWidget = localStorage.getItem('TWILIO_WEBCHAT_WIDGET');

            let conversationSid = null;
            let token = null;
            let gpt = null;
    
            if (twilioWebchatWidget) {
                const { conversationSid: extractedConversationSid, token: extractedToken, gpt: extractedGpt } = JSON.parse(twilioWebchatWidget);
                conversationSid = extractedConversationSid;
                token = extractedToken;
                gpt = extractedGpt;
            }

            if(historicalGptChatMessagesData && historicalGptChatMessagesData.length > 0) {

                const messagesLastTwoEnquryAndGptToken: Message[] = [];

                messagesLastTwoEnquryAndGptToken.push({
                    body: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 2].body.replace(/"/g, "“"),
                    author: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 2].author,
                    dateUpdated: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 2].dateUpdated,
                    dateCreated: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 2].dateCreated,
                    attributes: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 2].attributes,
                    index: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 2].index
                } as Message);
                    
                messagesLastTwoEnquryAndGptToken.push({
                    body: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 1].body.replace(/"/g, "“"),
                    author: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 1].author,
                    dateUpdated: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 1].dateUpdated,
                    dateCreated: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 1].dateCreated,
                    attributes: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 1].attributes,
                    index: historicalGptChatMessagesData[historicalGptChatMessagesData.length - 1].index
                } as Message);
                
                messagesLastTwoEnquryAndGptToken.push({
                    body: gpt,
                    author: 'gpt',
                    dateUpdated: new Date(),
                    dateCreated: new Date(),
                    attributes: {},
                    index: -10000
                } as Message);

                messagesLastTwoEnquryAndGptToken.push({
                    body: token,
                    author: 'token',
                    dateUpdated: new Date(),
                    dateCreated: new Date(),
                    attributes: {},
                    index: -10001
                } as Message);

                messagesLastTwoEnquryAndGptToken.push({
                    body: conversationSid,
                    author: 'conversationSid',
                    dateUpdated: new Date(),
                    dateCreated: new Date(),
                    attributes: {},
                    index: -10002
                } as Message);

                const messagesLastTwoEnquryAndGptTokenJsonString = JSON.stringify(messagesLastTwoEnquryAndGptToken);
                const lastMessage = historicalGptChatMessagesData[historicalGptChatMessagesData.length - 1].body;

                if (
                lastMessage.includes("BTTCODE723589") ||
                lastMessage.includes("BTTCODE175829") ||
                lastMessage.includes("BTTCODE500500") ||
                lastMessage.includes("BTTCODE501501")
                ) {
                    updateTwilioChatLaunch(`${messagesLastTwoEnquryAndGptTokenJsonString}`);
                    updateIsGptChatActive(false);
                }
            }

            setGptChatMessages([]);

            /*
             * [DEBUG CONSOLE]
             * console.log("End GPT chat");
             */

            setGptChatOneQueueCompleted(false);
        }

    }, [gptChatMessages, gptChatOneQueueCompleted]);

    useEffect(() => {
       
        if(sendingGptMessage !== "") {
            handleGptCall(sendingGptMessage);
        }
    }, [sendingGptMessage]);

    useEffect(() => {
        const messageListener = (message: Message) => {
            // Should focus latest message if one arrives while messages are not focused
            if (!document.activeElement?.hasAttribute("data-message-bubble")) {
                setShouldFocusLatest(true);
            }

            // Ensure that any new message sent by the current user is within scroll view.
            const belongsToCurrentUser = message.author === conversationsClient?.user.identity;
            if (belongsToCurrentUser) {
                scrollToBottom();
            }
        };

        conversation?.addListener("messageAdded", messageListener);

        return () => {
            conversation?.removeListener("messageAdded", messageListener);
        };
    }, [conversation, conversationsClient]);

    useEffect(() => {
        const checkIfAllMessagesLoaded = async () => {
            const totalMessagesCount = await conversation?.getMessagesCount();
            if (totalMessagesCount) {
                setHasLoadedAllMessages(totalMessagesCount === messages?.length);
            }

            // if messages were added to state, loading is complete
            if (messages && oldMessagesLength.current < messages?.length) {
                isLoadingMessages.current = false;
                oldMessagesLength.current = messages.length;
                realTimeMaxIndex.current = (messages ? messages[messages.length - 1].index : 0);
            }
        };

        checkIfAllMessagesLoaded();
        
        if (userId.length !== 34 && messages && messages[0]) {
            messages.forEach((message) => {
                if (message.author.length === 34) {
                    setUserId(message.author);
                }
              });
        }

        if (messages && messages[0] && messages[messages.length - 1].author === "Alice" && messages[messages.length - 1].body.includes("GTGPTCODE903248")) {
            /*
             * [DEBUG CONSOLE]
             * console.log("Start GPT chat");
             */
            let extractedAccountNumber = null;

            if ( messages[messages.length - 1].body.includes("Account Number:") ) {
                const accountNumberRegex = /Account Number:(\d+)/;
                const matches = messages[messages.length - 1].body.match(accountNumberRegex);
                if ( matches && matches.length > 1 ) {
                    extractedAccountNumber = matches[1];
                }

                const twilioWebchatWidget = localStorage.getItem('TWILIO_WEBCHAT_WIDGET');

                if ( twilioWebchatWidget ) {
                    const { conversationSid: extractedConversationSid, token: extractedToken, gpt: extractedGpt } = JSON.parse(twilioWebchatWidget);

                    const newTwilioWebchatWidget = JSON.stringify({
                        conversationSid: extractedConversationSid,
                        token: extractedToken,
                        gpt: extractedGpt,
                        accountNumber: extractedAccountNumber
                    });

                    localStorage.setItem('TWILIO_WEBCHAT_WIDGET', newTwilioWebchatWidget);
                }
            }
            updateIsGptChatActive(true);
        }
    }, [messages, conversation]);

    const handleScroll = async (event: UIEvent<HTMLDivElement>) => {
        const element = event.target as HTMLDivElement;
        const hasReachedTop =
            element.scrollHeight + element.scrollTop - MESSAGES_SPINNER_BOX_HEIGHT <= element.clientHeight;

        // When reaching the top of all messages, load the next chunk
        if (hasReachedTop && conversation && messages && !hasLoadedAllMessages && !isLoadingMessages.current) {
            isLoadingMessages.current = true;
            oldMessagesLength.current = messages.length;
            realTimeMaxIndex.current = (messages ? messages[messages.length - 1].index : 0);
            const totalMessagesCount = await conversation?.getMessagesCount();

            if (totalMessagesCount && messages.length < totalMessagesCount) {
                dispatch(getMoreMessages({ anchor: totalMessagesCount - messages.length - 1, conversation }));
            }
        }
    };

    const renderSeparatorIfApplicable = (message: Message) => {
        const belongsToCurrentUser = message.author === conversationsClient?.user.identity;
        const isFirstUnreadMessage = message.index === (conversation?.lastReadMessageIndex as number) + 1;

        /*
         * Render New separator above the first unread message
         * (messages sent by the current user should be treated as inherently read to avoid flicker)
         */
        if (isFirstUnreadMessage && !belongsToCurrentUser) {
            return <MessageListSeparator message={message} separatorType="new" />;
        }

        return null;
    };

    const renderChatStarted = () =>
        hasLoadedAllMessages ? (
            <>
                <Box {...conversationEventContainerStyles}>
                    <Text as="h3" {...conversationEventTitleStyles} data-test="chat-started">
                        Chat started
                    </Text>
                    <Text as="p" {...conversationEventDateStyles}>
                        {conversation?.dateCreated.toLocaleString()}
                    </Text>
                </Box>
            </>
        ) : null;

    const renderChatItems = () => {
        if (!messages) {
            return null;
        }


        const messagesAbove: Message[] = [];
        const messagesBelow: Message[] = [];

        let above = true;
        let doNotIncludeCount = 0;

        messages.forEach((message: Message) => {
            if (message.author !== "Alice" && (message.body.includes("BTTCODE"))) {
                /*
                 * [DEBUG CONSOLE]
                 * console.log("Do not include this message.");
                 */
                doNotIncludeCount += 1;
                return; //continue;
            }

            if(above === true) {
                messagesAbove.push({
                    body: message.body,
                    author: message.author,
                    dateUpdated: message.dateUpdated,
                    dateCreated: message.dateCreated,
                    attributes: message.attributes,
                    index: message.index
                } as Message);
            }
            else {
                messagesBelow.push({
                    body: message.body,
                    author: message.author,
                    dateUpdated: message.dateUpdated,
                    dateCreated: message.dateCreated,
                    attributes: message.attributes,
                    index: message.index
                } as Message);
            }

            if (message.author === "Alice" && message.body.includes("GTGPTCODE903248")) {
                /*
                 * [DEBUG CONSOLE]
                 * console.log("Above false detected.");
                 */
                above = false;
                if(gptChatLogLoaded === false && historicalGptChatMessages.length < 1 && messages.length > 2) {
                    //console.log("gpt chat will be loaded here.");
                    setGptChatLogLoaded(true);

                    let foundUser = "";

                    for (const messageX of messages) {
                        if (messageX.author.length === 34) {
                            foundUser = messageX.author;
                          break;
                        }
                    }

                    const messagesSuper: Message[] = [];

                     
                    getGptChatByChid(foundUser);

                    setHistoricalGptChatMessages(messagesSuper);
                }
            }
        });


        const messagesYYY: Message[] = [];

        historicalGptChatMessages.forEach((message: Message, index: number) => {
            messagesYYY.push({
                body: replaceHtmlTags(message.body, message.author),
                author: message.author,
                dateUpdated: message.dateUpdated,
                dateCreated: message.dateCreated,
                attributes: message.attributes,
                index: (index + 2) * -1
            } as Message);
        });

        const spinnerIndex = (messages[0]?.index || 0) - 1;

        const messagesOriginal = [
            {
                index: spinnerIndex
            } as Message,
            ...messagesAbove,
            ...(messagesYYY || []),
            ...(gptChatMessages || []),
            ...messagesBelow
        ];

        const messagesWithSpinner: Message[] =[];

        messagesOriginal.forEach((message: Message) => {
            if(message.author === "Alice") {
                messagesWithSpinner.push({
                    body: message.body,
                    author: "Willa",
                    dateUpdated: message.dateUpdated,
                    dateCreated: message.dateCreated,
                    attributes: message.attributes,
                    index: message.index
                } as Message);
            }
            else {
                messagesWithSpinner.push({
                    body: message.body,
                    author: message.author,
                    dateUpdated: message.dateUpdated,
                    dateCreated: message.dateCreated,
                    attributes: message.attributes,
                    index: message.index
                } as Message);
            }
        });

        return messagesWithSpinner.map((message: Message, i: number) => {
            // First message in array represents the loading spinner
            if (message.index === spinnerIndex) {
                // Only render loading spinner if there are remaining messages to load
                return hasLoadedAllMessages ? null : (
                    <Box {...spinnerContainerStyles} key={message.index}>
                        <Spinner color="colorTextWeak" decorative={false} title="Loading" />
                    </Box>
                );
            }
            // Discount loading spinner from indices

            i -= 1;
            i -= (messagesYYY?.length || 0);
            i -= (gptChatMessages?.length || 0);
            i -= doNotIncludeCount;

            //const totalCount = messagesAbove.length + (messagesYYY || []).length + (gptChatMessages || []).length + messagesBelow.length;

            const totalCount = (messagesAbove.length + messagesBelow.length) - doNotIncludeCount;

            return (
                <Box data-test="all-message-bubbles" key={message.index}>
                    {renderSeparatorIfApplicable(message)}
                    <MessageBubble
                        message={message}
                        isLast={i === totalCount - 1}
                        // isLastOfUserGroup={isLastOfUserGroup(message, i, messages)}
                        focusable={message.index === focusIndex}
                        updateFocus={updateFocus}
                    />
                    {// if message is interactiveWebchatOptions and latest message, show Interactives
                    'interactiveWebchatOptions' in message.attributes && i === totalCount - 1 && conversationStateToCheckInMessageList === "active" &&
                        <Interactives
                            key="interactives"
                            message={message}
                            conversation={conversation}
                        />
                    }
                </Box>
            );
        });
    };

    const handleFocus = () => {
        // Hand over focus to message bubbles once there is at least one
        if (messages && messages.length && focusIndex < 0) {
            setFocusIndex(messages[messages.length - 1].index);
        }
    };

    return (
        <Box {...messageListStyles}>
            <Box {...outerContainerStyles} onScroll={throttle(handleScroll, 1000)} ref={messageListRef} role="main">
                <Box
                    aria-label="Chat messages"
                    role="log"
                    aria-relevant="additions"
                    {...innerContainerStyles}
                    tabIndex={focusIndex >= 0 ? -1 : 0}
                    onFocus={handleFocus}
                >
                    {renderChatStarted()}
                    {renderChatItems()}
                    {participants?.length === 1 && (isAlicePreparing !== "") ? 
                    (!isOnline ? <Text {...participantTypingStyles} as="p"></Text> :
                        isAlicePreparing === "Preparing" ? <Text {...participantTypingStyles} as="p">Willa is preparing...</Text> : 
                    <Text {...participantTypingStyles} as="p">Willa is typing...</Text>) : participants
                        ?.filter((p) => p.isTyping && p.identity !== conversationsClient?.user.identity)
                        .map((p) => (
                            <Text {...participantTypingStyles} as="p" key={p.identity}>
                                {users?.find((u) => u.identity === p.identity)?.friendlyName} is typing...
                            </Text>
                        ))}
                </Box>
            </Box>
        </Box>
    );
};
