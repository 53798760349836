// Package Imports
import {Button} from '@twilio-paste/core/button';
import {Box} from '@twilio-paste/core/box';
import {useState} from 'react';
import {
  buttonRightAlignStyles,
	buttonBaseRightAlignStyles
} from "./styles/Customize.styles";

// Component
const Buttons = ({ curInteractives, sendMessage }) => {
  const [isDisabled, setDisabled] = useState(false);
  // Render
  return (
    <div style={buttonBaseRightAlignStyles}>
      {curInteractives.options.map((o) => (
        <Box style={buttonRightAlignStyles} key={`key${o.uuid}`} marginBottom="space30">
          <Button element="Box"
            key={o.uuid}
            disabled={isDisabled}
            onClick={() => {
              setDisabled(true);
              sendMessage(o.content);}}
            fullWidth={false}
            variant="destructive_secondary"
          >
            {o.content}
          </Button>
        </Box>
      ))}
    </div>
  );
};

export default Buttons;
