import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";
import { CSSProperties } from "react";

export const outerContainerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "column",
    marginBottom: "space80"
};

export const bubbleAndAvatarContainerStyles: BoxStyleProps = {
    display: "flex",
    alignItems: "flex-end"
};

export const getInnerContainerStyles = (belongToCurrentUser: boolean): BoxStyleProps => ({
    marginLeft: belongToCurrentUser ? "auto" : "space0",
    marginRight: belongToCurrentUser ? "space0" : "auto",
    maxWidth: "90%",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: belongToCurrentUser ? "row-reverse" : "row"
});

/*
 *export const getInnerBoxStyles = (belongToCurrentUser: boolean): BoxStyleProps => ({
 *    paddingTop: "space30",
 *    paddingBottom: "space30",
 *    paddingLeft: "space40",
 *    paddingRight: "space40",
 *    backgroundColor: belongToCurrentUser ? "colorBackgroundPrimaryStronger" : "colorBackground",
 *    backgroundImage: belongToCurrentUser ? "linear-gradient(176deg, #e0005a 5%, #e10757 66%, #f6921e 103%)" : "none",
 *    color: belongToCurrentUser ? "colorTextWeakest" : "colorText",
 *    borderRadius: "borderRadius30",
 *    borderBottomRightRadius: belongToCurrentUser ? "borderRadius0" : "borderRadius30",
 *    borderBottomLeftRadius: belongToCurrentUser ? "borderRadius30" : "borderRadius0"
 *});
 */

export const getInnerBoxStyles = (belongToCurrentUser: boolean): CSSProperties => ({
    padding: ".5rem .75rem",
    backgroundColor: belongToCurrentUser ? "#fcebf3" : "#f7f7f7",
    // backgroundImage: belongToCurrentUser ? "linear-gradient(176deg, #e0005a 5%, #e10757 66%, #f6921e 103%)" : "none",
    color: belongToCurrentUser ? "#4a3b3b" : "#191919",
    borderRadius: "13px",
    borderBottomRightRadius: belongToCurrentUser ? "0" : "13px",
    borderBottomLeftRadius: belongToCurrentUser ? "13px" : "0"
});

export const authorStyles: TextStyleProps = {
    color: "inherit",
    fontWeight: "fontWeightBold",
    fontSize: "fontSize30",
    wordBreak: "break-word",
    textOverflow: "ellipsis", // doesn't get applied for some reason – need to investigate
    overflow: "hidden"
};

export const timeStampStyles = (belongToCurrentUser: boolean): TextStyleProps => ({
    fontSize: "fontSize20",
    color: "inherit",
    marginLeft: belongToCurrentUser ? "auto" : "space0",
    marginRight: belongToCurrentUser ? "space0" : "auto",
    paddingLeft: belongToCurrentUser ? "space0" : "space20",
    paddingRight: belongToCurrentUser ? "space20" : "space0",
});

/*
 * export const bodyStyles: TextStyleProps = {
 *     color: "inherit",
 *     // whiteSpace: "pre-wrap",
 *     wordBreak: "break-word",
 *     fontSize: "fontSize50",
 *     lineHeight: "lineHeight30",
 *     letterSpacing: "Spacing40"
 * };
 */

export const bodyStyles: CSSProperties = {
    color: "inherit",
    wordBreak: "break-word",
    fontSize: "1rem",
    lineHeight: "1.3",
    letterSpacing: ".2px"
};

export const readStatusStyles: TextStyleProps = {
    textAlign: "right",
    fontSize: "fontSize10",
    marginRight: "space20",
    color: "colorText"
};
