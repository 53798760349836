import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { ScreenReaderOnly } from "@twilio-paste/core";
import { useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { Key, KeyboardEvent, useEffect, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";

import { AppState } from "../store/definitions";
import { FilePreview } from "./FilePreview";
import {
    getInnerContainerStyles,
    getInnerBoxStyles,
    authorStyles,
    timeStampStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles
} from "./styles/MessageBubble.styles";

const doubleDigit = (number: number) => `${number < 10 ? 0 : ""}${number}`;

export const MessageBubble = ({
    message,
    isLast,
    focusable,
    updateFocus,
}: {
    message: Message;
    isLast: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
}) => {
    const [read, setRead] = useState(false);
    const { conversationsClient, participants, users, fileAttachmentConfig } = useSelector((state: AppState) => ({
        conversationsClient: state.chat.conversationsClient,
        participants: state.chat.participants,
        users: state.chat.users,
        fileAttachmentConfig: state.config.fileAttachment
    }));
    const messageRef = useRef<HTMLDivElement>(null);

    const belongsToCurrentUser = message.author === conversationsClient?.user.identity;
    const isError = message.body.includes("Hello! How may I assist you with your Megatel services today?") ||
                    message.body.includes("Sorry, your message didn't come through to me. Could you please retype it again?") ||
                    message.body.includes("죄송합니다. 메시지가 정상적으로 전송되지 않았습니다. 메시지를 다시 입력해 주시겠어요?") ||
                    message.body.includes("很抱歉， 信息传送失败，可以请您重新输入您的消息吗？") ||
                    message.body.includes("I apologize for the inconvenience. The connection has unexpectedly been interrupted. Allow me to transfer you to someone who will be able to assist you with your inquiry. Please wait a moment.") ||
                    message.body.includes("불편을 드려 정말 죄송합니다. 예기치 않게 연결이 끊어졌습니다. 문의에 도움을 드릴 수 있는 담당자에게 연결해 드리겠습니다. 잠시만 기다려 주세요.") ||
                    message.body.includes("很抱歉， 连接意外中断了。我先帮您转接客服人员。请稍等") ? true : false;

    /*
     * console.log("MSG BODY HERE:");
     * console.log(message.body);
     * console.log(isError);
     */
    
    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                    getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    const renderMedia = () => {
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) {
                return null;
            }

            return message.attachedMedia.map((media: Media, index: Key) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={index} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>Media messages are not supported</i>;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleFocus = () => {
        // Necessary since screen readers can set the focus to any focusable element
        updateFocus(message.index);
    };

    const author = users?.find((u) => u.identity === message.author)?.friendlyName || message.author;

    return (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >

                <Box {...bubbleAndAvatarContainerStyles}>
                    <Box {...getInnerContainerStyles(belongsToCurrentUser)}>
                        <Box style={getInnerBoxStyles(belongsToCurrentUser)}>
                            {!belongsToCurrentUser && (
                                <Flex hAlignContent="between" width="100%" vAlignContent="center" marginBottom="space20">
                                    <Text {...authorStyles} as="p" aria-hidden style={{ textOverflow: "ellipsis" }} title={author}>
                                        {author}
                                    </Text>
                                
                                <ScreenReaderOnly as="p">
                                    {belongsToCurrentUser
                                        ? "You sent at"
                                        : `${users?.find((u) => u.identity === message.author)?.friendlyName} sent at`}
                                </ScreenReaderOnly>

                            </Flex>)}
                            <Text as="p" style={bodyStyles} dangerouslySetInnerHTML={{__html: message.body}}>
                            </Text>
                        {message.type === "media" ? renderMedia() : null}
                        </Box>
                        <Box>
                            <Text {...timeStampStyles(belongsToCurrentUser)} as="p">
                                {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(
                                    message.dateCreated.getMinutes()
                                )}`}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                {isError ?  <div style={{ textAlign: 'right', color: "#FF0C0C", display: 'flex', alignItems: 'center', marginTop: '0.25rem' }}>
                                <img style={{ marginLeft: '12.625rem' }} src='/assets/imgs/twilio/circle-exclamation-light.svg' />
                                <div style={{ marginLeft: '0.25rem' }}>Not Delivered</div>
                            </div>: null}
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles}>
                        Read
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
        </Box>
    );
};
